.my-gallery {
  position: relative;
  scroll-snap-type: both mandatory;
  scroll-padding: 1rem;
}

.delete-icon {
  position: absolute;
  left: 0;
  height: 32px;
  width: 32px;
  cursor: pointer;
}


.galley-item-border {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 4vw;
  right: 4vw;
  background-image: repeating-linear-gradient(
      60deg,
      #ddb171,
      #ddb171 17px,
      transparent 17px,
      transparent 34px,
      #ddb171 34px
    ),
    repeating-linear-gradient(
      150deg,
      #ddb171,
      #ddb171 17px,
      transparent 17px,
      transparent 34px,
      #ddb171 34px
    ),
    repeating-linear-gradient(
      240deg,
      #ddb171,
      #ddb171 17px,
      transparent 17px,
      transparent 34px,
      #ddb171 34px
    ),
    repeating-linear-gradient(
      330deg,
      #ddb171,
      #ddb171 17px,
      transparent 17px,
      transparent 34px,
      #ddb171 34px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.gallery-item-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
}

.my-carrosel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  opacity: 0.2;
}

.gallery-item {
  position: relative;
  width: 500px;
  height: 500px;
}

.my-gallery .gallery-item:not(.active) {
  flex: 0;
  display: none;
}

.active {
  scroll-snap-type: unset;
}

.gallery-item.active img {
  animation-name: square-image;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.gallery-item.active .galley-item-border {
  animation-name: border-square;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.button-prev {
  position: absolute;
  left: 0;
  top: 50%;
  height: 48px;
  width: 48px;
  z-index: 999;
  cursor: pointer;
  fill: #d4b171;
}

.button-next {
  position: absolute;
  right: 0;
  top: 50%;
  height: 48px;
  width: 48px;
  z-index: 999;
  cursor: pointer;
  fill: #d4b171;
}

li {
  scroll-snap-align: center;
  display: inline-block;
  border-radius: 3px;
  font-size: 0;
}

@media (max-width: 1100px) {
  #home {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .container-corrosel {
      display: none !important;
  }
}
