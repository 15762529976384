.project {
  letter-spacing: normal;
  line-height: 1.5em;
  font-size: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .container .row {
    flex-direction: row !important;
  }

  .edit-mode {
    right: -30px;
  } 

  .close-icon {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    fill: #d4b171;
    z-index: 999999;
  }

  .section-title h2 {
    font-size: 40px;
  }

  .section-title {
    display: flex;
    flex-wrap: wrap;
    color: #d2ae6d;
    margin-top: 0px;
    font-family: "Kirvy-Bold";
  }

  .as-carrocel {
    flex: 1;
  }

  .content-info {
    gap: 28px;
    display: flex;
    text-align: justify;
    margin-top: 28px;
    flex: 1;
    flex-wrap: wrap;

    .section-title {
      flex: 2;
    }

    .project-text {
      flex: 8;
    }

    .informations-project {
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-family: "Kirvy-Bold";
    }
  }

  .gallery-item img {
    width: 100% !important;
  }

  .gallery-item {
    width: 100% !important;
  }

  .my-carrosel {
    display: block;
  }
}
