@keyframes fadeUp {
  0% {
      opacity: 0;
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -ms-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -o-transform: translateY(-25px)
  }

  100% {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0)
  }
}

@-webkit-keyframes fadeUp {
  0% {
      opacity: 0;
      transform: translateY(-25px);
      -webkit-transform: translateY(-25px);
      -moz-transform: translateY(-25px);
      -ms-transform: translateY(-25px);
      -o-transform: translateY(-25px)
  }

  100% {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0)
  }
}

@keyframes toggler-first {
  0% {
      top: 21%
  }

  50% {
      top: 52%;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0)
  }

  100% {
      top: 52%;
      transform: rotate(132deg);
      -webkit-transform: rotate(132deg);
      -moz-transform: rotate(132deg);
      -ms-transform: rotate(132deg);
      -o-transform: rotate(132deg)
  }
}

@media all and (max-width: 1681px) {
  @keyframes toggler-first {
      0% {
          top:21%
      }

      50% {
          top: 51%;
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0)
      }

      100% {
          top: 51%;
          transform: rotate(132deg);
          -webkit-transform: rotate(132deg);
          -moz-transform: rotate(132deg);
          -ms-transform: rotate(132deg);
          -o-transform: rotate(132deg)
      }
  }
}

@media all and (max-width: 1540px) {
  @keyframes toggler-first {
      0% {
          top:21%
      }

      50% {
          top: 50%;
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0)
      }

      100% {
          top: 50%;
          transform: rotate(132deg);
          -webkit-transform: rotate(132deg);
          -moz-transform: rotate(132deg);
          -ms-transform: rotate(132deg);
          -o-transform: rotate(132deg)
      }
  }
}

@media all and (max-width: 1281px) {
  @keyframes toggler-first {
      0% {
          top:21%
      }

      50% {
          top: 51%;
          transform: rotate(0);
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0)
      }

      100% {
          top: 51%;
          transform: rotate(132deg);
          -webkit-transform: rotate(132deg);
          -moz-transform: rotate(132deg);
          -ms-transform: rotate(132deg);
          -o-transform: rotate(132deg)
      }
  }
}

@keyframes toggler-last {
  0% {
      bottom: 21%
  }

  50% {
      bottom: 44%;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0)
  }

  100% {
      bottom: 44%;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg)
  }
}

@keyframes toggler-middle {
  0% {
      opacity: 1
  }

  50% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

@keyframes toggler-first-reverse {
  0% {
      top: 45%;
      transform: rotate(132deg);
      -webkit-transform: rotate(132deg);
      -moz-transform: rotate(132deg);
      -ms-transform: rotate(132deg);
      -o-transform: rotate(132deg)
  }

  50% {
      top: 45%;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0)
  }

  100% {
      top: 21%
  }
}

@keyframes toggler-last-reverse {
  0% {
      bottom: 44%;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg)
  }

  50% {
      bottom: 44%;
      transform: rotate(0);
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0)
  }

  100% {
      bottom: 21%
  }
}

@keyframes toggler-middle-reverse {
  0% {
      opacity: 0
  }

  50% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

@keyframes fadeLeft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
      -webkit-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -o-transform: translateX(-100px)
  }

  100% {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0)
  }
}

@-webkit-keyframes fadeLeft {
  0% {
      opacity: 0;
      transform: translateX(-100px);
      -webkit-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      -o-transform: translateX(-100px)
  }

  100% {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0)
  }
}

@keyframes fadeRight {
  0% {
      opacity: 0;
      transform: translateX(100px);
      -webkit-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -o-transform: translateX(100px)
  }

  100% {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0)
  }
}

@-webkit-keyframes fadeRight {
  0% {
      opacity: 0;
      transform: translateX(100px);
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px)
  }

  100% {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0)
  }
}

@-webkit-keyframes spin {
  0% {
      opacity: 0;
      transform: rotateY(0);
      -webkit-transform: rotateY(0);
      -ms-transform: rotateY(0);
      -moz-transform: rotateY(0);
      -o-transform: rotateY(0)
  }

  100% {
      opacity: 1;
      transform: rotateY(360deg);
      -webkit-transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      -o-transform: rotateY(360deg)
  }
}

@keyframes spin {
  0% {
      opacity: 0;
      transform: rotateY(0);
      -webkit-transform: rotateY(0);
      -ms-transform: rotateY(0);
      -moz-transform: rotateY(0);
      -o-transform: rotateY(0)
  }

  100% {
      opacity: 1;
      transform: rotateY(360deg);
      -webkit-transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      -moz-transform: rotateY(360deg);
      -o-transform: rotateY(360deg)
  }
}

@keyframes border-square {
  0% {
      transform: rotate(0) scale(0);
      -webkit-transform: rotate(0) scale(0);
      -moz-transform: rotate(0) scale(0);
      -ms-transform: rotate(0) scale(0);
      -o-transform: rotate(0) scale(0)
  }

  100% {
      transform: rotate(180deg) scale(1);
      -webkit-transform: rotate(180deg) scale(1);
      -moz-transform: rotate(180deg) scale(1);
      -ms-transform: rotate(180deg) scale(1);
      -o-transform: rotate(180deg) scale(1)
  }
}

@keyframes square-image {
  0% {
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0)
  }

  100% {
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1)
  }
}
