.bar-devevelopment {
  position: absolute;
  z-index: 999999;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: wheat;
  display: flex;
  flex-direction: column;

  .form-bar-dev {
    flex: 10 1;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    flex-direction: column;
    height: 100%;

  }

  footer {
    padding: 16px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex: 1;
  }
}

.img-devevelopment {
  position: relative;
  height: 28px;
  width: 28px;
  margin: 8px;
  cursor: pointer;
}
