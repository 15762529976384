.footer-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  background-color: #1E2D40;
  color: white;
  margin-top: 12px;
}

.footer-bar img {
  height: 32px;
}

.whatsapp-fixed{
  position: fixed;
  bottom: 100px;
  right: 0px;
}

.whatsapp-fixed img{
  height: 70px;
  width: 70px;
}

.footer {
  background-color: black;
  color: white;
}

.footer p{
  margin: 5px;
}

.container-footer{
  width: 100%;
}

.whatsapp-fixed img {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* torna o ícone redondo */
  animation: pulse 2s infinite; /* aplica a animação 'pulse' por 2 segundos em loop infinito */
}

@keyframes pulse {
  0% {
    border: 6px solid transparent; /* define a borda transparente no início */
  }
  50% {
    border: 6px solid #ffffff; /* define a borda branca na metade da animação */
  }
  100% {
    border: 6px solid transparent; /* define a borda transparente no final */
  }
}