
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}