#home {
  display: flex;
  width: 100%;
  margin-top: 100px;
  position: relative;
  transition: width 2s, height 4s;
  animation-name: fader;
  animation-duration: 5s;
}

@keyframes fader {
  from {
  opacity: 0
}
  to {
    opacity: 1
  }
}

#home .desc-home {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  letter-spacing: normal;
  text-align: center;
}
#menu .carroselArea {
}
#home .container-corrosel {
  flex: 1;
  display: flex;
}

#home .img-back-ground {
  position: absolute;
  width: 100%;
  height: 90%;
  object-fit: fill;
}

.home-item-desc h2 {
  font-family: "Kirvy-Bold";
  font-size: 3.5vw;
  color: #d2ae6d;
}

#home .subtitle .edit-mode{
  right: -48px;
}

#home .container-corrosel .edit-mode{
  right: 48px;
    top: 48px;
}

#home .title-home-ref .edit-mode{
  right: -2px;
}

.home-item-desc {
  animation-name: fadeUp;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

.home-item-desc .subtitle {
  font-size: 1.5vw;
  color: #ffff;
  margin-bottom: 2vw;
}
.img-back-ground {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@media (min-width: 1200px) {
  h2,
  h2 {
    font-size: 2rem;
  }
}
