#contact{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
#contact .container{
  margin: auto;
  width: 80%;
}

.button-submit {
  float: right;
  width: 150px;
  height: 40px;
}