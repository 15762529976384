.collapse.in:not(.show) {
  display: flex !important; /*gambi pra deixar invalido mesmo*/
  flex-direction: column;
}
/* Navigation */
#menu {
  transition: all 0.8s;
  margin: 0;
  position: fixed;
}

#menu .navbar-nav {
  flex-direction: row;
  padding-left: 173px;
}

.container-routes .collapse .navbar-collapse{
  align-items: center;
  justify-content: center;
}

#menu .navbar-header .logo-link {
  flex: 1;
  padding-left: 157px;
}

.img-logo-header {
  max-height: 100px;
}

.navbar-header a:hover,
a:focus {
  text-decoration: none !important;
  transition: all 0.3s cubic-bezier(1, 0.8, 0.18, 0.71) !important;
  transform: none !important;
}

#menu .navbar-header {
  flex: 8 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#menu .navbar-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*#menu .navbar-logo:first-child a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}*/

#menu .container-routes {
  flex: 10;
}

.logo {
  padding: 4px;
}

#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0.34);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}

#menu.navbar-default .navbar-nav > li > a {
  /*font-family: "Lato", sans-serif;*/
  color: #555;
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
