.icons-social-media {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon img:hover {
  border-radius: 50px;
  box-shadow: inset 5em 1em rgb(236, 229, 213);
  transform: scale(1) !important;
}

.icon img{
  background-color: rgb(212,179,116);
  border-radius: 50px;
}
