#login {
  display: flex;
  align-items: center;

  .button-submit {
    margin: 16px 0px;
  }

  .form__field {
    font-size: 20px;
  }

  .btn-to-sent-code-sms{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
