#carroucel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


#carroucel .carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#carroucel .carousel-image.active {
  opacity: 1;
}