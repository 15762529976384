#img-about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#img-about img {
  max-width: 350px !important;
  padding-top: 50px;
}

#about {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 100px;
}

#about .container .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#about .container .row .portfolio-items {
  flex: 10;
  max-width: 1000px;
  position: relative;
}

#about .with-project {
  flex: 10;
  max-width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#about .container .row .section-title {
  flex: 2;
  max-width: 900px;
}

#about .container-name-about {
  position: relative;
  width: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about .about-text {
  label {
    display: inline-block;
    width: 100%;
    text-align: justify;
    font-weight: normal;
    margin-bottom: 0;
  }
}

#about .about-text {
  font-size: 16px;
  padding: 18px;
}

@media (max-width: 1100px) {
  #about .about-text {
    font-size: 12px;
    padding: 14px;
  }
}
