$primary: #dccaa9;
$secondary: #DBBE8A;
$gray: #9b9b9b;

.form__group {
  position: relative;
  padding: 15px 0 0;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  color: #9b9b9b;
  padding: 10px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: $gray;
  font-size: 14px;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    color: $primary;
    font-weight: 700;
  }
  font-weight: 500;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
