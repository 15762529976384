#button {
  background-color: #d3af6eba;
  border-radius: 6px;
  border-style: none;
  font-family: 'Kirvy-bold';


  &:disabled {
    background-color: #f5e2bfba;
    cursor: not-allowed;
  }
}
#button:hover:enabled {
  background-color: rgba(211, 175, 110, 0.8705882353);
  cursor: pointer;
}


