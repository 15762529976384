#portfolio {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 100px;
}

#portfolio .icon-back {
  float: left;
  font-size: 24px;
  color: #d2ae6d;
  font-weight: 800;
  cursor: pointer;
}

#portfolio .container .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#portfolio .container .row .portfolio-items {
  flex: 10;
  max-width: 1000px;
  position: relative;
}

#portfolio .with-project {
  flex: 10;
  max-width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#portfolio .container .row .section-title {
  flex: 2;
  max-width: 900px;
}

#portfolio .dialog .container {
  position: relative;
}

.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}

.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}

.container-image-gallery{
  width: 100%;  /* Ajusta a largura da div ao elemento pai */
  height: auto; /* Altura automática baseada no conteúdo */
  display: flex; /* Usa flexbox para alinhar o conteúdo */
  justify-content: center; /* Centraliza horizontalmente a imagem */
  align-items: center; /* Centraliza verticalmente a imagem */
  overflow: hidden;
}

.portfolio-item .hover-bg img {
  height: 200px;
  width: 100%;
  /* height: auto; */
   object-fit: fill;
}

.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    #d3af6eba 0%,
    rgba(211, 175, 110, 0.8705882353) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  cursor: pointer;
}

.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}

.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.hover-bg:hover .hover-text {
  opacity: 1;
}
