body.no-scroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .full-scree-mode{

    .gallery-item {
      width: 100% !important;
      height: 100% !important;
    }
  }
  
}


.container-icon-close-full-screen {
  position: fixed;
  top: 0;
  z-index: 99999;
  right: 0;
  padding: 0;


  .icon-back-project {
    background: rgb(118 118 118 / 50%);
  }

  .icon-close {
    float: right;
    font-size: 24px;
    color: #d2ae6d;
    font-weight: 800;
    cursor: pointer;
  }
}

.full-scree-mode {
  .button-prev,
  .button-next {
    height: 100px;
    width: 100px;
  }

  .my-carrosel {
    position: fixed;
    top: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    background: white;
  }

  .my-gallery {
    height: 100%;
    width: 100%;
  }

  .gallery-item {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
